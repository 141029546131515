.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ECEFF1;
  /* background-color: #cfd8dc; */
  /* backgroundColor: '#ECEFF1',
  minHeight: '95vh',
  padding: 15 */
}

.hide {
  display: none;
}

.txtCenter {
  text-align: center;
}